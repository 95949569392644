// noinspection DuplicatedCode

import { createSelector } from '@ngrx/store';
import { getBookingStatus, getBookingStatusColor } from 'src/app/shared/mappings/mappings';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { AuthSelectors } from '../../auth/store';
import {
  getLocationAddress,
  getPriceType,
  mapQueryParamsToPage,
  mapQueryParamsToSearchForm,
} from '../../shared/mappings/mappings';
import { Area } from '../../shared/models/area.model';
import { Business } from '../../shared/models/business.model';
import {
  BusinessDetailsVM,
  BusinessListVM,
  BusinessSearchResponse,
  BusinessTripListVM,
} from '../../shared/models/business.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { FirestoreSelector } from '../../store/firestore';
import { SupportAgentState } from '../../store/support-agent.state';

export const businessesState = (state: SupportAgentState) => state.businessesState;

export const isUserSignedIn = createSelector(AuthSelectors.signedInSelector, isSignedIn => isSignedIn);

export const userAreaIdsSelector = createSelector(AuthSelectors.userAreaIdsSelector, areaIds => areaIds);

export const storagePageSizeSelector = createSelector(
  AuthSelectors.browserStorageSelector,
  storage => storage?.find(item => item.key === 'pageSize:supportAgent:businesses')?.value,
);

export const dispatchTagsSelector = createSelector(FirestoreSelector.dispatchTagsSelector, tags => tags);

export const areasSelector = createSelector(FirestoreSelector.areaListSelector, areas => areas);

export const productFeatureListSelector = createSelector(
  FirestoreSelector.productFeatureListSelector,
  features => features,
);

export const isLoadingSelector = createSelector(businessesState, state => state.isLoading);

export const businessesSelector = createSelector(businessesState, state => state.businesses);

export const businessSelector = createSelector(businessesState, state => state.business);

export const businessIdSelector = createSelector(businessesState, state => state.businessId);

export const queryParamsSelector = createSelector(businessesState, state => state.queryParams);

export const searchResponseSelector = createSelector(businessesState, state => state.searchResponse);

export const userPageSizeSelector = createSelector(businessesState, state => state.userPageSize);

export const tripSearchResponseSelector = createSelector(businessesState, state => state.tripSearchResponse);

export const businessTripsSelector = createSelector(businessesState, state => state.trips);

export const businessListSelector = createSelector(businessesSelector, businesses =>
  businesses ? mapBusinessesToBusinessList(businesses) : null,
);

function mapBusinessesToBusinessList(businesses: Business[]): BusinessListVM[] {
  return businesses.map(business => ({
    id: business.id,
    name: business.name,
  }));
}

export const businessDetailsVMSelector = createSelector(businessSelector, business =>
  business ? mapBusinessToBusinessDetailsVM(business) : null,
);

function mapBusinessToBusinessDetailsVM(business: Business): BusinessDetailsVM {
  return {
    id: business.id,
    name: business.name,
  };
}

export const searchResponseIdsSelector = createSelector(searchResponseSelector, response =>
  response ? mapSearchResponseHitsToList(response) : [],
);

function mapSearchResponseHitsToList(response: SearchResponse<BusinessSearchResponse>): string[] {
  // eslint-disable-next-line no-underscore-dangle
  return response.hits?.hits?.map(hit => hit._id);
}

export const totalRecordsSelector = createSelector(searchResponseSelector, response =>
  response?.hits ? response.hits.total.value : 0,
);

export const listPageSize = createSelector(storagePageSizeSelector, userPageSizeSelector, (storage, state) =>
  !state ? (!storage ? 10 : storage) : state,
);

export const pageSelector = createSelector(queryParamsSelector, listPageSize, (params, pageSize) =>
  mapQueryParamsToPage(params, pageSize),
);

export const pageSizeSelector = createSelector(pageSelector, page => page.pageSize);

export const pageIndexSelector = createSelector(pageSelector, page => page.pageIndex);

export const searchFormSelector = createSelector(queryParamsSelector, userAreaIdsSelector, (params, areaIds) =>
  mapQueryParamsToSearchForm(params, areaIds),
);

export const tripSearchResponseIdsSelector = createSelector(
  tripSearchResponseSelector,
  // eslint-disable-next-line no-underscore-dangle
  response => (response ? response.hits?.hits?.map(hit => hit._id) : []),
);

export const tripSearchResponseTotalRecords = createSelector(tripSearchResponseSelector, response =>
  response?.hits ? response.hits.total.value : 0,
);

export const businessTripListSelector = createSelector(businessTripsSelector, areasSelector, (bookings, areas) =>
  bookings ? mapBookingsToTripList(bookings, areas) : null,
);

function mapBookingsToTripList(bookings: BookingAggregate[], areas: Area[]): BusinessTripListVM[] {
  return bookings.map(aggregate => mapBooking(aggregate, areas));
}

function mapBooking(aggregate: BookingAggregate, areas: Area[]): BusinessTripListVM {
  const booking = aggregate.booking;
  const trip = aggregate.trip;
  const vehicleLicensePlate = trip?.vehicle?.license_plate;
  const tripPrice = aggregate.trip_price;

  const pickup =
    trip && trip.pickup
      ? getLocationAddress(trip.pickup)
      : booking && booking.pickup
        ? getLocationAddress(booking.pickup)
        : '';

  const dropoff =
    trip && trip.dropoff
      ? getLocationAddress(trip.dropoff)
      : booking && booking.dropoff
        ? getLocationAddress(booking.dropoff)
        : '';

  const driverFullName = trip?.driver != null ? trip.driver.first_name + ' ' + trip.driver.last_name : '';

  return {
    bookingId: aggregate.doc_id,
    createdAt: booking.created_at,
    pickupAt: booking.pickup_at,
    pickup,
    dropoff,
    driverFullName,
    vehicleLicensePlate,
    totalPrice: tripPrice?.customer_net_amount?.display,
    priceType: tripPrice ? getPriceType(tripPrice.type) : '',
    timeZoneId: mapBookingAggrToAreaTimeZone(areas, booking.area_id),
    rating: aggregate.driver_rating?.rating,
    status: getBookingStatus(aggregate),
    statusColorClass: getBookingStatusColor(getBookingStatus(aggregate)),
  };
}

function mapBookingAggrToAreaTimeZone(areas: Area[], areaId: string) {
  return areas.find(area => area.id === areaId)?.time_zone_id;
}
